.none {
    visibility: hidden;
}

.color-nav {
    background-color: E3BAC6;
}

.link { 
    color: black!important;
    z-index: 5;
    font-family: "karla", sans-serif;
}

.navbar-toggler {
    z-index: 5;
}