.page-footer {
    background-color: #dbdbdb;
    color: #fff;
    padding: 20px 0;
    margin-top: 20px;
}

.list-unstyled li a {
    color: black!important;
    text-decoration: none;
}

.footer-copyright {
    color: black;
}

.list-unstyled {
    display: flex;
    padding-inline-start: 0px;
    margin-block-end: 0px;
    margin-block-start: 0px;
    justify-content: center;
}


