@import url('https://fonts.googleapis.com/css2?family=Karla:wght@500;700&family=Open+Sans:wght@300;600&family=Poppins:wght@600;700&display=swap');

.maxwidth-none {
    max-width: none!important;
}

html body {
    background-color: transparent;
    
}

.calculator {
    background-color: transparent;
}

.dark {
    background-color: #212529!important;
    border: 1px solid #212529!important;
    color: white!important;
}


.content {
	max-width: 50em;
	margin: 0 auto;
	transform: skewY(0deg);
}


.diagonal-box {
	background-image: linear-gradient(45deg, #654ea3, #eaafc8);
	transform: skewY(0deg);
}


.red {
    background-color: #9c2d2d!important;
    color: white!important;
}

.yellow {
    background-color: #aba627!important;
    color: white!important;
}


.green {
    background-color: #4d9946!important;
    color: white!important;
}

.diagonal-box {
    position: relative;
    height: 50em;
}

.diagonal-box:before {
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   background-image: linear-gradient(45deg, #654ea3, #eaafc8); 	
   transform: skewY(-51deg);
}

.content {

 
   margin: 0 auto;
   position: relative;
   top: 30%;
   
}

.title {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 700;
    letter-spacing: 0px;
    color: white;
}

.color-button { 
    background-color: #D89A9E!important;
    border-color: #D89A9E!important;
}

.ab {
    z-index: 90;
}

.par {
    text-align: start!important;
} 


.page-footer {
    background-color: #dbdbdb;
    color: #fff;
    padding: 20px 0;
    margin-top: 20px;
}

.list-unstyled li a {
    color: black!important;
    text-decoration: none;
}

.footer-copyright {
    color: black;
}

.list-unstyled {
    display: flex;
    padding-inline-start: 0px;
    margin-block-end: 0px;
    margin-block-start: 0px;
    justify-content: center;
}



iframe {
    position: absolute;
}

